html {
  --nav-size: 20px;
  --title-size: 18px;
  --content-size: 16px;
  --mobile-normal-size: 12px;
  --mini-size: 10px;
  --mobile-mini-size: 8px;
  --korbank-purple: #92278f;
  --lightgray: #e7e8e9;
  --background: #f5f5f5;
  --korbank-grandient: linear-gradient(to right, #973694, #462355);
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
